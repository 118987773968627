import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
//const LaunchEventLayout = import('./containers/LaunchEventLayout');
const LaunchEventLayout = React.lazy(() => import('./containers/LaunchEventLayout'));

// Pages

class App extends Component {

  render() {
    /*if(window.isLandingPage) {
      return (
        <BrowserRouter basename={`landing-page/${window.landingPagePath}`}>
            <React.Suspense fallback={loading}>
              <Switch>
              <Route path="/" name="Register For Event" render={props => <LaunchEventLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      );
    }*/
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
            <Route path="/order-received-thank-you/:order" name="Order Received Thanks" render={props => <LaunchEventLayout {...props}/>} />
            <Route path="/launch-event" name="Launch Event" render={props => <LaunchEventLayout {...props}/>} />

            <Redirect from="/checkout-selector" to="/offer-selector"/>
            <Redirect from="/offer-selector/n3z5-hello-kit" to="/offer-selector/n3z5-ohel1.1"/> 
            <Route path="/offer-selector/:offer" name="Offer Selector" render={props => <LaunchEventLayout {...props}/>} />
            <Route path="/offer-selector/:offer/:paymentGateway" name="Offer Selector" render={props => <LaunchEventLayout {...props} />} />
            <Redirect from="/offer-selector" to="/offer-selector/cons3.1"/>

            <Route path="/thanks-page" name="Thanks" render={props => <LaunchEventLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
